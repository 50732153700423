<template>
    <div v-if="isLoading" class="loader bg-[#fff] rounded-[8px] p-2">
        <LoadingComponent />
    </div>
    <div v-else class="company-container bg-[#fff] rounded-[8px] p-2">
        <div class="navigation-tabs">
            <div class="nav-links">
                <router-link to="/companyprofile" :class="`${this.$route.path == '/companyprofile' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'list-check']" class="nav-icon" />
                    <span>Company profile</span>
                </router-link>

                <router-link to="/settings" :class="`${this.$route.path == '/userrofile' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Recruiter profile</span>
                </router-link>
                <router-link to="/InvitedCoworkerTab" :class="`${this.$route.path == '/InvitedCoworkerTab' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Invited Coworkers</span>
                </router-link>
            </div>
        </div>

        <div class="profile_header">
            <div class="profile_cover">
                <img loading="lazy" decoding="async" class="cover_img" :src="getImgUrl(this.company.cover)" alt="" />
                <label for="cover_image" class="edit_photo">
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/icon_cam.svg" width="25" height="25" />
                </label>
                <input type="file" id="cover_image" @change="change_cover($event)" />
            </div>
            <div class="profile_infos">
                <div class="profile_image relative">
                    <!-- <div class="absolute w-full h-full top-0 left-0 z-5"></div> -->
                    <img loading="lazy" decoding="async" class="profile_img" :src="getImgUrl(company.logo)" alt="" />
                    <label for="profile_image" class="edit_photo">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/icon_cam.svg" width="25" height="25" />
                    </label>
                    <input type="file" id="profile_image" @change="change_profileImg($event)" />
                </div>
                <div class="infos">
                    <span>
                        {{ company.name }}
                        <button @click="toggleModal">
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/icon_edit.svg" width="25" height="25" />
                        </button>
                    </span>
                    <span>{{ company.slogan }}</span>
                    <div>
                        <span><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_category.svg" width="17" height="15" alt="" /> {{ company.industry }}</span>
                        <span><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_location.svg" width="17" height="15" alt="" /> {{ company.location }}</span>
                        <span><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_bag.svg" width="17" height="15" alt="" /> {{ company.employees_count }} employees</span>
                        <span><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_type.svg" width="17" height="15" alt="" /> {{ company.type }}</span>
                    </div>
                </div>
                <div class="links">
                    <a :href="company.website" target="_blank" rel="noopener noreferrer"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_website.svg" width="15" height="15" alt=""
                    /></a>
                    <a :href="company.facebook" target="_blank" rel="noopener noreferrer"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_facebook.svg" width="15" height="15" alt=""
                    /></a>
                    <a :href="company.linkedin" target="_blank" rel="noopener noreferrer"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_linkedIn.svg" width="15" height="15" alt=""
                    /></a>
                </div>
            </div>
        </div>
        <div class="overview">
            <span class="title">Overview</span>
            <div class="about">
                <span>Get to know {{ company.name }}</span>
                <p v-html="company.description"></p>
            </div>

            <div class="benefits">
                <span class="titre">Benefits</span>
                <div>
                    <span v-for="(benefit, index) in company.benefit_list" :key="index">
                        <img loading="lazy" decoding="async" :src="require(`@/assets/Images/icons/${benefit.icon_path}`)" alt="" />
                        {{ benefit.benefit }}
                    </span>
                </div>
                <span class="titre">Team</span>
                <div class="team">
                    <div class="member" v-for="(member, index) in company.team" :key="index">
                        <img loading="lazy" decoding="async" :src="require(`@/assets/Images/icons/${member.image}`)" alt="" width="35" height="35" />
                        <span>
                            {{ member.name }}
                            <span class="role">{{ member.role }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tags">
            <div class="tag" v-for="(tag, index) in company.taglist" :key="index">
                <img loading="lazy" decoding="async" :src="require(`@/assets/Images/icons/${tag.image}`)" alt="" height="73" width="73" />
                <span>
                    {{ tag.title }}
                    <span class="text">{{ tag.text }}</span>
                </span>
            </div>
        </div>
        <!-- <div class="jobs_search">
            <div class="search">
                <span> Roles at {{ company.name }}</span>
                <input type="text" name="" id="title" placeholder="Search by Title" />
                <select name="" id="category" placeholder="Role Category">
                    <option value="">Role Category</option>
                    <option value="Compensation & Benefit Consultant">Compensation & Benefit Consultant</option>
                </select>
                <select name="" id="experience" placeholder="Experience Requirement">
                    <option value="">Experience Requirement</option>
                    <option value="Finance">Finance</option>
                </select>
                <select name="" id="location" placeholder="Working Location">
                    <option value="">Working Location</option>
                    <option value="jakerta">Jakerta</option>
                </select>
            </div>
            <div class="jobs">
                <div v-for="(job, index) in jobList" :key="index">
                    <div class="job_card">
                        <img loading="lazy" decoding="async" :src="require(`@/assets/Images/icons/${job.picture}`)" width="38" height="38" alt="" />
                        <span class=".span_2_cols">{{ job.role }}</span>
                        <span class=".span_2_cols">{{ job.company }}</span>
                        <div>
                            <span class=".span_2_cols"><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_share.svg" width="14" height="14" alt="" />{{ job.location }}</span>
                            <span class=".span_2_cols"><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_people.svg" width="14" height="14" alt="" />{{ job.type }}</span>
                            <span class=".span_2_cols"><img loading="lazy" decoding="async" src="../assets/Images/icons/icon_file.svg" width="14" height="14" alt="" />{{ job.salary }}</span>
                        </div>
                        <span class=".span_2_cols">{{ job.OnSite }}</span>
                        <button>Easy Apply</button>
                    </div>
                </div>
            </div>
            <button>
                More
                <img loading="lazy" decoding="async" src="../assets/Images/icons/icon_down_arrow.svg" alt="" />
            </button>
        </div> -->
        <!-- <div class="our_company">
            <span
                >Our company
                <label for="cover_image" class="edit_photo">+ Add photos</label>
                <input type="file" id="cover_image" @change="add_photos($event)" multiple />
            </span>
            <imageCarousel :Images="company.company_images"></imageCarousel>
        </div> -->
    </div>
</template>

<script>
// import imageoCarousel from "../components/imageCarousel.vue";
import LoadingComponent from "../components/LoadingComponent.vue";
import axios from "axios";

export default {
    name: "companyProfile",
    components: {
        // imageCarousel,
        LoadingComponent,
    },
    data() {
        return {
            selected: [true, false, false],
            current: 0,
            show: false,
            company: {},
            isLoading: true,
            logo: "",
            cover: "",
            jobList: [
                {
                    picture: "profile_image.svg",
                    role: "Compensation & Benefit Consultant",
                    company: "Deall Jobs",
                    location: "online",
                    type: "Freelance",
                    salary: "Negotiable",
                    OnSite: "Remote",
                },
                {
                    picture: "profile_image.svg",
                    role: "Compensation & Benefit Consultant",
                    company: "Deall Jobs",
                    location: "online",
                    type: "Freelance",
                    salary: "Negotiable",
                    OnSite: "Remote",
                },
                {
                    picture: "profile_image.svg",
                    role: "Compensation & Benefit Consultant",
                    company: "Deall Jobs",
                    location: "online",
                    type: "Freelance",
                    salary: "Negotiable",
                    OnSite: "Remote",
                },
                {
                    picture: "profile_image.svg",
                    role: "Compensation & Benefit Consultant",
                    company: "Deall Jobs",
                    location: "online",
                    type: "Freelance",
                    salary: "Negotiable",
                    OnSite: "Remote",
                },
                {
                    picture: "profile_image.svg",
                    role: "Compensation & Benefit Consultant",
                    company: "Deall Jobs",
                    location: "online",
                    type: "Freelance",
                    salary: "Negotiable",
                    OnSite: "Remote",
                },
                {
                    picture: "profile_image.svg",
                    role: "Compensation & Benefit Consultant",
                    company: "Deall Jobs",
                    location: "online",
                    type: "Freelance",
                    salary: "Negotiable",
                    OnSite: "Remote",
                },
            ],
        };
    },
    computed: {},
    methods: {
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        toggleModal: function () {
            this.show = !this.show;
        },
        updateProfile: function (updates) {
            this.company = updates;
            this.toggleModal();
            let updatedCompany = {
                name: this.company.name,
                slogan: this.company.slogan,
                industry: this.company.industry,
                location: this.company.location,
                employees_count: this.company.employees_count,
                type: this.company.type,
                description: this.company.description,
                taglist: this.company.taglist,
                benefit_list: this.company.benefit_list,
                team: this.company.team,
                website: this.company.website,
                facebook: this.company.facebook,
                linkedIn: this.company.linkedIn,
                instagram: this.company.instagram,
                twitter: this.company.twitter,
            };

            axios
                .put("https://server.go-platform.com/company/update", {
                    name: this.company.name,
                    updates: updatedCompany,
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        change_cover(event) {
            if (event.target.files[0]?.type !== "image/png") {
                alert("Please upload a PNG file");
                return;
            }

            this.cover = event.target.files[0];
            var formdata = new FormData();
            formdata.append("cover", this.cover);
            var requestOptions = {
                method: "POST",
                body: formdata,
                credentials: "include",
                redirect: "follow",
                withCredentials: true,
            };

            fetch("https://server.go-platform.com/company/changeCover", requestOptions).then(async (res) => {
                console.log(res);
                await this.fetchCompanyData();
            });
        },
        change_profileImg(event) {
            if (event.target.files[0]?.type !== "image/png") {
                alert("Please upload a PNG file");
                return;
            }
            if (event.target.files[0].size > 100000) {
                alert("please upload a logo with smaller size");
                return;
            }

            this.company.profile_image = event.target.files[0].name;
            this.logo = event.target.files[0];
            this.uploadNewLogo(this.logo);
        },
        uploadNewLogo(logo) {
            console.log({ NEWLOGO: logo });
            var formdata = new FormData();
            formdata.append("logo", logo);
            var requestOptions = {
                method: "POST",
                body: formdata,
                credentials: "include",
                redirect: "follow",
                withCredentials: true,
            };

            fetch("https://server.go-platform.com/company/changeLogo", requestOptions).then(async (res) => {
                console.log(res);
                await this.fetchCompanyData();
            });
        },
        uploadNewCover(cover) {
            var formdata = new FormData();
            formdata.append("cover", cover);
            var requestOptions = {
                method: "POST",
                body: formdata,
                credentials: "include",
                redirect: "follow",
                withCredentials: true,
            };

            fetch("https://server.go-platform.com/company/changeCover", requestOptions).then((res) => {
                console.log(res);
            });
        },
        add_photos: function (event) {
            this.company.company_images = event.target.files;
        },
        getImgUrl(imgFileId) {
            console.log({ imgFileId });
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                return image;
            }
            console.log({ MSG: "NO COVER" });
            return "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png";
        },

        async fetchCompanyData() {
            this.isLoading = true;
            await axios
                .get("https://server.go-platform.com/company/info", {
                    withCredentials: true,
                    headers: {
                        "Cache-Control": "no-cache", // or 'no-store'
                    },
                })
                .then((res) => {
                    console.log({ res: res.data });
                    this.company = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log({ err: err.response.data });
                });
        },
    },
    async mounted() {
        // await axios
        //   .get("https://server.go-platform.com/company/info", {
        //     withCredentials: true,
        //   headers: {
        //     "Cache-Control": "no-cache", // or 'no-store'
        //   },
        //   })
        //   .then((res) => {
        //     this.company = res.data;
        // })
        // .catch((err) => {
        //   console.log({ err: err.response.data });
        // });

        await this.fetchCompanyData();
    },
};
</script>

<style lang="scss" scoped>
body {
    font-family: "Inter";
    font-style: normal;
}

.company-container {
    padding: 24px 8px;

    //div{border: 2px solid #000;}
    .profile_header {
        margin: 1rem 0;

        .edit_photo {
            display: flex;
            border-radius: 50%;
            padding: 2%;
            justify-content: center;
            align-items: center;
            position: relative;
            background: transparent;
            height: fit-content;
            background: rgba(0, 0, 0, 0.5);
        }

        .edit_photo:hover {
            background: rgba(0, 0, 0, 0.8);
        }

        .profile_cover {
            position: relative;
            justify-content: flex-end;
            display: flex;
            width: 100%;
            height: 14rem;
            margin-bottom: -8rem;

            label {
                right: 15px;
                top: 10px;
            }

            .cover_img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 7px;
                object-fit: cover;
            }
        }

        .profile_infos {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 5fr 1fr;
            padding: 3rem 1rem;
            padding-right: 0;
            //margin-top: 4%;

            .profile_image {
                position: relative;
                width: 150px;
                height: 150px;
                padding: 1%;
                grid-row: 1 / span 2;
                align-items: flex-end;
                display: flex;
                align-self: flex-end;

                label {
                    left: 79%;
                    top: 1%;
                }

                .profile_img {
                    background-color: #fff;
                    width: 100%;
                    height: fit-content;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border: 3px solid #fff;
                    border-radius: 10px;
                    object-fit: cover;
                }
            }

            .infos {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                grid-column: 2;
                grid-row: 2;
                padding-left: 3%;
                gap: 0.5rem;

                > :first-child {
                    font-weight: 700;
                    font-size: 21.0298px;
                    line-height: 21px;
                    color: #2f343a;
                    display: flex;
                    gap: 1rem;
                    align-items: center;

                    button {
                        background: none;
                        border: none;
                    }
                }

                > :nth-child(2) {
                    font-weight: 600;
                    font-size: 14.0199px;
                    line-height: 14px;
                    color: #2f343a;
                }

                > :nth-child(3) {
                    font-weight: 600;
                    font-size: 12.2674px;
                    line-height: 18px;
                    text-align: center;
                    color: #585f65;
                    width: 100%;
                    display: flex;
                    gap: 1rem;

                    span {
                        width: fit-content;
                        border: 0.876242px solid #e9eaed;
                        border-radius: 2.62873px;
                        padding: 1%;
                        display: flex;
                        align-items: center;
                        gap: 0.25rem;
                    }
                }
            }

            .links {
                display: flex;
                align-items: flex-end;
                gap: 0.5rem;
                grid-column: 3;
                grid-row: 2;

                a {
                    background: #fff;
                    height: fit-content;
                    padding: 1%;
                    width: fit-content;
                    border: 0.876242px solid #dfe2e6;
                    border-radius: 3.50497px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5%;
                }
            }
        }
    }

    .overview {
        font-weight: 400;
        font-size: 12.2674px;
        line-height: 18px;
        color: #2f343a;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        padding-left: 2rem;
        margin: 1rem 0;

        .title {
            font-weight: 700;
            font-size: 13.1436px;
            line-height: 18px;
            text-align: center;
            color: #2196f3;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            grid-row: 1;
            grid-column: 1 / span 3;
        }

        .title::after {
            background: #b9b9b9;
            width: 100%;
            height: 1px;
            content: "";
            margin: 0.5rem 0;
        }

        .about {
            grid-column: 1 / span 2;

            p {
                line-height: 22px;
            }

            span {
                font-weight: 700;
                font-size: 14.0199px;
                line-height: 18px;
            }
        }

        .culture {
            grid-column: 1 / span 2;
            height: fit-content;
            display: flex;
            flex-direction: column;

            p {
                line-height: 22px;
            }

            span {
                font-weight: 700;
                font-size: 14.0199px;
                line-height: 18px;
            }
        }

        .benefits {
            grid-column: 3;
            grid-row: 2 / span 2;
            display: flex;
            flex-direction: column;
            height: fit-content;
            padding-left: 2rem;

            .titre {
                font-weight: 700;
                font-size: 12.2674px;
                line-height: 18px;
                margin: 1rem 0;
                display: flex;
                align-items: center;
                gap: 0.25rem;
            }

            .titre::after {
                background: #b9b9b9;
                width: 100%;
                height: 0.5px;
                content: "";
            }

            > :nth-child(2) {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                gap: 0.5rem;

                span {
                    font-weight: 500;
                    font-size: 12.2674px;
                    line-height: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0.5rem;
                    gap: 0.25rem;
                    background: #ffffff;
                    border: 0.876242px solid #e9eaed;
                    border-radius: 3.50497px;
                    height: fit-content;
                }
            }

            .team {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                gap: 0.75rem;
                padding: 0 1rem;
                height: 20rem;
                padding-right: 2rem;

                .member {
                    background: #fff;
                    border: 0.876242px solid #e9eaed;
                    border-radius: 3.50497px;
                    padding: 1rem;
                    display: flex;
                    gap: 1rem;

                    span {
                        display: flex;
                        flex-direction: column;
                        font-weight: 700;
                        font-size: 12.2674px;
                        line-height: 18px;

                        .role {
                            font-weight: 500;
                            font-size: 12.2674px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;
        margin: 2rem 0 0;

        .tag {
            flex-basis: 30%;
            display: flex;
            align-items: center;
            padding: 1%;
            background: #fff;
            border: 0.876242px solid #e9eaed;
            border-radius: 12.2674px;
            gap: 0.5rem;

            span {
                font-weight: 700;
                font-size: 12.2674px;
                line-height: 18px;
                text-transform: uppercase;
                color: #2f343a;
                display: flex;
                flex-direction: column;

                .text {
                    font-weight: 500;
                    font-size: 10.2674px;
                    line-height: 14px;
                    text-transform: lowercase;
                }
            }
        }
    }

    .jobs_search {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        margin-top: 2rem;

        .search {
            background: #2196f3;
            color: #fff;
            display: grid;
            grid-template-rows: 1fr 2fr;
            padding: 1rem;
            gap: 1rem;
            padding-right: 5rem;

            span {
                grid-row: 1;
                font-weight: 700;
                font-size: 14.0199px;
                line-height: 18px;
            }

            input,
            select {
                grid-row: 2;
                background: #ffffff;
                border-radius: 4.38121px;
                color: #d4d4d4;
                text-align: center;
                border: none;
            }

            select {
                color: #2196f3;
            }
        }

        .jobs {
            background: transparent;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 1rem 2rem;

            .job_card {
                //background: #fff;
                display: grid;
                grid-template-rows: 1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr 5fr;
                padding: 1rem;
                padding-bottom: 0;
                width: 80%;
                border-bottom: 1px solid #b9b9b9;
                margin-top: 1rem;

                .span_2_cols {
                    grid-column: 2;
                }

                img {
                    grid-column: 1;
                    grid-row: 1 / span 4;
                    object-fit: cover;
                }

                > :nth-child(2) {
                    grid-row: 1;
                    //grid-column: 2 / span 3;
                    font-weight: 700;
                    font-size: 12.2674px;
                    line-height: 18px;
                }

                > :nth-child(3) {
                    grid-row: 2;
                    font-weight: 400;
                    font-size: 10.5149px;
                    line-height: 14px;
                }

                > :nth-child(4) {
                    display: flex;
                    gap: 1rem;
                    grid-row: 3;
                    font-weight: 400;
                    font-size: 8.76242px;
                    line-height: 14px;

                    span {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                    }
                }

                > :nth-child(5) {
                    grid-row: 4;
                    font-weight: 600;
                    font-size: 8.76242px;
                    line-height: 14px;
                    color: #dd4d99;
                    background: #fdf2f9;
                    border-radius: 10.5149px;
                    width: fit-content;
                    height: fit-content;
                    padding: 1%;
                }

                button {
                    grid-row: 1;
                    background: #ffffff;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10.5149px;
                    color: #2196f3;
                    border: none;
                    padding: 0 0.75rem;
                    font-weight: 500;
                    font-size: 12.2674px;
                    line-height: 18px;
                    width: max-content;
                }
            }
        }

        > :last-child {
            display: flex;
            background: transparent;
            border: none;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
        }
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #dbdbdc;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #b8b8b9;
    }

    input[type="file"] {
        display: none;
    }

    .our_company {
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        width: 100%;
        gap: 2rem;

        span {
            font-weight: 500;
            font-size: 27.3142px;
            line-height: 41px;
            display: flex;
            justify-content: space-between;

            label {
                background: none;
                border: none;
                color: #2196f3;
                font-size: 18px;
            }
        }
    }
}

/*----------------------------------------------------------------*/

.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 32px;
    padding-left: 48px;
    border-bottom: 2px solid #edeff2;
    margin-bottom: 25px;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}

.loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
